<template>
  <div class="add_line">
    <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px" size="mini">
      <div class="modular_box">
        <div class="head">
          <div class="line"></div>
          <div class="title">基本信息</div>
        </div>
        <div class="check_box">
          <el-form-item label="始发站：" prop="startAddress">
            <el-cascader :disabled="editDisabled" @keydown.native="trimLR" filterable v-model="addForm.startAddress" :options="options" clearable :props="{value: 'code',label:'name', children:'children',checkStrictly: true }" size="mini" class="screenInput"></el-cascader>
          </el-form-item>
          <el-form-item label="目的站：" prop="endAddress">
            <el-cascader :disabled="editDisabled" @keydown.native="trimLR" filterable v-model="addForm.endAddress" :options="options" clearable :props="{value: 'code',label:'name', children:'children',checkStrictly: true}" size="mini" class="screenInput"></el-cascader>
          </el-form-item>
          <el-form-item label="时效类型：" prop="speedType">
            <el-select :disabled="editDisabled" class="screenInput" v-model="addForm.speedType" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in agingList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类型：" prop="goodsType">
            <el-select :disabled="editDisabled" class="screenInput" v-model="addForm.goodsType" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in goodsList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="生效日期：" prop="validStart">
            <el-date-picker :picker-options="pickerOptions" :disabled="editDisabled" class="screenInput" v-model="addForm.validStart" value-format="yyyy-MM-dd" @change="(val)=> dateStatus(val,'1')" type="date" placeholder="选择生效日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="失效日期：" prop="validEnd">
            <el-date-picker class="screenInput" :disabled="detailsDisabled" v-model="addForm.validEnd" value-format="yyyy-MM-dd" @change="(val)=> dateStatus(val,'2')" type="date" placeholder="选择失效日期"></el-date-picker>
          </el-form-item>
        </div>

        <div class="formula">
          <div class="formula_text">有效期：</div>
          <div class="formula_tips">
            <div>生效日期0点到失效日期24点</div>
          </div>
        </div>
      </div>

      <div class="modular_box">
        <div class="head">
          <div class="line"></div>
          <div class="title">价格配置</div>
        </div>
        <div class="check_box">
          <el-form-item label="计费方式：" prop="chargeType">
            <el-select class="screenInput" v-model="addForm.chargeType" :disabled="detailsDisabled" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in chargeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="进价方式：" prop="roundType">
            <el-select class="screenInput" v-model="addForm.roundType" :disabled="detailsDisabled" placeholder="请选择" size="mini" clearable>
              <el-option v-for="item in purchaseList" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="weight_box">
          <div class="function_button">
            <el-button type="button" :disabled="detailsDisabled" size="mini" @click="addWeight">添加重量分段</el-button>
            <span class="tips_text">（分段信息左包含，单位为kg）</span>
          </div>

          <div class="subsection_box" v-for="(item,index) in weiCfgList" :key="index">
            <div class="title">
              <span class="sign" v-if="addForm.chargeType != 3">*</span>
              分段{{index + 1}}
            </div>
            <div class="section_box">
              <el-input v-model="item.subMin" :disabled="detailsDisabled" oninput="value=value.replace(/[^0-9.]/g,'')" class="inputStyle" size="mini" @blur="checkWeiCfg(item,index)"></el-input>
              <span class="division">—</span>
              <el-input v-model="item.subMax" :disabled="detailsDisabled" oninput="value=value.replace(/[^0-9.]/g,'')" class="inputStyle" size="mini"></el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">首重</span>
              <el-input v-model="item.initWeight" :disabled="detailsDisabled" class="inputStyle2" size="mini" @blur="firstWeight(item,index)">
                <template slot="append">kg</template>
              </el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">首重价格</span>
              <el-input v-model="item.initWeightPrice" :disabled="detailsDisabled" class="inputStyle2" size="mini">
                <template slot="append">元</template>
              </el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">续重价格</span>
              <el-input v-model="item.otherWeightPrice" :disabled="detailsDisabled" class="inputStyle2" size="mini">
                <template slot="append">kg</template>
              </el-input>
            </div>

            <div class="delete_btn">
              <el-button type="info" size="mini" :disabled="detailsDisabled" @click="deleteSegments(index)">删除分段</el-button>
            </div>

          </div>

          <div class="formula">
            <div class="formula_text">计算公式：</div>
            <div class="formula_tips">
              <div>重量计费价格=首重价格 + (计费重量 - 首重) × 续重价格</div>
              <div>计费重量不大于首重时，只收首重价格</div>
            </div>
          </div>
        </div>

        <!-- 体积分段 -->
        <div class="weight_box">
          <div class="function_button">
            <el-button type="button" :disabled="detailsDisabled" size="mini" @click="addVolume">添加体积分段</el-button>
            <span class="tips_text">（分段信息左包含，单位为方）</span>
          </div>

          <div class="subsection_box" v-for="(item,index) in volCfgList" :key="index">
            <div class="title">
              <span class="sign" v-if="addForm.chargeType != 2">*</span>
              分段{{index + 1}}
            </div>
            <div class="section_box">
              <el-input v-model="item.subMin" :disabled="detailsDisabled" oninput="value=value.replace(/[^0-9.]/g,'')" class="inputStyle" size="mini" @blur="checkVolCfg(item,index)"></el-input>
              <span class="division">—</span>
              <el-input v-model="item.subMax" :disabled="detailsDisabled" oninput="value=value.replace(/[^0-9.]/g,'')" class="inputStyle" size="mini"></el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">首方</span>
              <el-input v-model="item.initVolume" :disabled="detailsDisabled" class="inputStyle2" size="mini" @blur="firstParty(item,index)">
                <template slot="append">方</template>
              </el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">首方价格</span>
              <el-input v-model="item.initVolumePrice" :disabled="detailsDisabled" class="inputStyle2" size="mini">
                <template slot="append">元</template>
              </el-input>
            </div>

            <div class="first_weight">
              <span class="weight_text">续方价格</span>
              <el-input v-model="item.otherVolumePrice" :disabled="detailsDisabled" class="inputStyle2" size="mini">
                <template slot="append">方</template>
              </el-input>
            </div>

            <div class="delete_btn">
              <el-button type="info" size="mini" :disabled="detailsDisabled" @click="deleteSegments2(index)">删除分段</el-button>
            </div>

          </div>

          <div class="formula">
            <div class="formula_text">计算公式：</div>
            <div class="formula_tips">
              <div>体积计费价格=首方价格 + (计费方 - 首方) × 续方价格</div>
              <div>计费重量不大于首方时，只收首方价格</div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="bottom_btn">
      <el-button type="reset" size="small" @click="cancel">取 消</el-button>
      <span v-if="!detailsDisabled" style="padding-left: 15px;">
        <el-button v-if="this.$route.query.status == 'edit'" type="button" size="small" @click="submitForm('addForm')">保 存</el-button>
        <el-button v-else type="button" size="small" @click="submitForm('addForm')">保 存</el-button>
      </span>

    </div>
  </div>
</template>

<script>
import {
  addressList,
  agingType,
  productType,
  billingMethod,
  purchaseMethod,
  addLine,
  lineDetails,
} from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  data() {
    return {
      options: [],
      agingList: [],
      goodsList: [],
      chargeList: [], //计费方式
      purchaseList: [], //进价方式
      addForm: {
        startAddress: [],
        endAddress: [],
        speedType: "",
        goodsType: "",
        validStart: "",
        validEnd: "",
        chargeType: "",
        roundType: "",
      },
      rules: {
        startAddress: [
          { required: true, message: "请选择始发站", trigger: "change" },
        ],
        endAddress: [
          { required: true, message: "请选择目的站", trigger: "change" },
        ],
        speedType: [
          { required: true, message: "请选择时效类型", trigger: "change" },
        ],
        goodsType: [
          { required: true, message: "请选择产品类型", trigger: "change" },
        ],
        validStart: [
          { required: true, message: "请选择生效日期", trigger: "change" },
        ],
        validEnd: [
          { required: true, message: "请选择失效日期", trigger: "change" },
        ],
        chargeType: [
          { required: true, message: "请选择计费方式", trigger: "blur" },
        ],
        roundType: [{ required: true, message: "进价方式", trigger: "blur" }],
      },
      weiCfgList: [
        {
          subMin: "",
          subMax: "",
          initWeight: "",
          initWeightPrice: "",
          otherWeightPrice: "",
        },
      ],
      volCfgList: [
        {
          subMin: "",
          subMax: "",
          initVolume: "",
          initVolumePrice: "",
          otherVolumePrice: "",
        },
      ],
      editDisabled: false,
      detailsDisabled: false,
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000; //  - 86400000是否包括当天
        },
      },
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.getAddress();
    this.getAging();
    this.getProduct();
    this.getCharging(); //计费方式
    this.getPurchasePrice(); //进价方式
    if (this.$route.query.status != "add") {
      this.getDetails();
    }
    if (this.$route.query.status == "edit") {
      this.editDisabled = true;
    }
    if (this.$route.query.status == "details") {
      this.editDisabled = true;
      this.detailsDisabled = true;
    }
  },
  methods: {
    async getDetails() {
      var that = this;
      try {
        let data = {
          id: this.$route.query.row.id,
        };
        let res = await lineDetails(data);
        console.log(res, "详情");
        if (res.retCode == "0000000") {
          let data = res.rspBody;
          for (let items in data) {
            for (let key in that.addForm) {
              if (key == items) {
                that.addForm[key] = data[items];
              }
            }
          }
          //重量体积分段数据
          this.weiCfgList =
            data.weiCfgList.length > 0 ? data.weiCfgList : this.weiCfgList;
          this.volCfgList =
            data.volCfgList.length > 0 ? data.volCfgList : this.volCfgList;

          if (data.endAreaCode && data.endAreaCode != "") {
            this.addForm.endAddress = [
              data.endProvinceCode,
              data.endCityCode,
              data.endAreaCode,
            ];
          } else {
            this.addForm.endAddress = [data.endProvinceCode, data.endCityCode];
          }

          if (data.startAreaCode && data.startAreaCode != "") {
            this.addForm.startAddress = [
              data.startProvinceCode,
              data.startCityCode,
              data.startAreaCode,
            ];
          } else {
            this.addForm.startAddress = [
              data.startProvinceCode,
              data.startCityCode,
            ];
          }

          console.log(that.addForm, "addForm");
        }
      } catch (err) {
        console.log(err);
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addNewData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async addNewData() {
      var that = this;
      let row = this.$route.query;
      console.log(that.addForm, "that.addForm");
      if (that.addForm.startAddress.length == 1) {
        this.$message({
          type: "warning",
          message: "起始地址必须精确到市",
        });
        return;
      }
      if (that.addForm.endAddress.length == 1) {
        this.$message({
          type: "warning",
          message: "目的地必须精确到市",
        });
        return;
      }

      let sign = 1;
      let sign2 = 1;
      if (that.addForm.chargeType != 3) {
        that.weiCfgList.filter((item) => {
          for (let key in item) {
            console.log(key,'222',item[key]);
            if (item[key] == "" && item[key] != 0) {
              sign = 2;
            }
          }
        });
      }
      if (sign == 2) {
        this.$message({
          type: "warning",
          message: "当选择只按重量计费时，则重量项必填",
        });
        return;
      }

      if (that.addForm.chargeType != 2) {
        that.volCfgList.forEach((item) => {
          for (let key in item) {
            if (item[key] == "" && item[key] != 0) {
              sign2 = 2;
            }
          }
        });
      }
      if (sign2 == 2) {
        this.$message({
          type: "warning",
          message: "当选择只按体积计费时，则体积项必填",
        });
        return;
      }
      try {
        let data = {
          // id:row.id,
          speedType: that.addForm.speedType, //时效类型
          goodsType: that.addForm.goodsType, //产品类型
          chargeType: that.addForm.chargeType, //  计费方式
          roundType: that.addForm.roundType, //进价方式：
          validStart: that.addForm.validStart, // 生效日期：
          validEnd: that.addForm.validEnd, // 失效日期
        };

        data.startProvinceCode = that.addForm.startAddress[0];
        data.startCityCode = that.addForm.startAddress[1];
        data.startAreaCode = that.addForm.startAddress[2]
          ? that.addForm.startAddress[2]
          : "";

        data.endProvinceCode = that.addForm.endAddress[0];
        data.endCityCode = that.addForm.endAddress[1];
        data.endAreaCode = that.addForm.endAddress[2]
          ? that.addForm.endAddress[2]
          : "";

        // data.weiCfgList = that.weiCfgList; //重量数据
        // data.volCfgList = that.volCfgList; //体积数据

        let arr = that.weiCfgList.filter((item) => {
          if (
            item.subMax != "" &&
            item.subMin != "" &&
            item.initWeight != "" &&
            item.initWeightPrice != "" &&
            item.otherWeightPrice != ""
          )
            return item;
        });
        let arr2 = that.volCfgList.filter((item) => {
          if (
            item.subMax != "" &&
            item.subMin != "" &&
            item.initVolume != "" &&
            item.initVolumePrice != "" &&
            item.otherVolumePrice != ""
          )
            return item;
        });
        if (arr.length > 0) {
          data.weiCfgList = arr;
        }
        if (arr2.length > 0) {
          data.volCfgList = arr2;
        }

        if (row.status == "edit") {
          data.id = row.row.id;
        }

        let params = utils.removeNull(data);
        console.log(params, "params");
        let res = await addLine(params);
        console.log(res, "新路新增");
        if (res.retCode == "0000000") {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.$router.push({
            path: "/line/line",
          });
        } else {
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },
    //取消
    cancel() {
      this.$router.push({
        path: "/line/line",
      });
    },
    //日期选择
    dateStatus(val, index) {
      console.log(val, index);
      if (val == null) {
        if (index == 1) {
          this.addForm.validStart = "";
        } else {
          this.addForm.validEnd = "";
        }
      } else {
        var stare = "";
        var end = "";
        if (index == 1) {
          console.log(
            new Date().getTime() >= new Date(val).getTime() + 3600 * 1000 * 24
          );
          this.addForm.validStart = val + " 00:00:00";
        } else {
          this.addForm.validEnd = val + " 23:59:59";
        }
        stare = this.addForm.validStart.split(" ");
        end = this.addForm.validEnd.split(" ");
        console.log(stare, end);
        if (stare[0] && end[0]) {
          var date1 = new Date(stare[0]);
          var date2 = new Date(end[0]);
          if (date1 > date2) {
            this.$message({
              type: "warning",
              message: "失效日期不能小于生效日期",
            });
            this.addForm.validEnd = "";
          }
        }
      }
    },

    //分段起始值校验
    checkWeiCfg(val, sign) {
      //只有一条数据的时候不比对
      if (this.weiCfgList.length == 1) return;
      this.weiCfgList.forEach((item, index) => {
        // index == (sign - 1) 是拿到上一条数据
        if (index == sign - 1) {
          console.log(item);
          // 比对本次数据subMin起始值 是否小于上条数据的结束值
          if (Number(val.subMin) < Number(item.subMax)) {
            val.subMin = "";
            this.$message({
              type: "warning",
              message: "输入的分段起始值 要大于上一个分段结束值",
            });
          }
        }
      });
    },

    checkVolCfg(val, sign) {
      if (this.volCfgList.length == 1) return;
      this.volCfgList.forEach((item, index) => {
        if (index == sign - 1) {
          console.log(item);
          if (Number(val.subMin) < Number(item.subMax)) {
            val.subMin = "";
            this.$message({
              type: "warning",
              message: "输入的分段起始值 要大于上一个分段结束值",
            });
          }
        }
      });
    },

    //首重
    firstWeight(val, sign) {
      console.log(val, sign, "sign");
      this.weiCfgList.forEach((item, index) => {
        console.log(item, index, "index");
        if (index == sign) {
          if (Number(val.initWeight) >= Number(item.subMax)) {
            val.initWeight = "";
            this.$message({
              type: "warning",
              message: "分段" + (index + 1) + "的首重不可大于该段的最大值",
            });
          }
        }
      });
    },

    //首方校验
    firstParty(val, sign) {
      console.log(val, sign, "sign");
      this.volCfgList.forEach((item, index) => {
        console.log(item, index, "index");
        if (index == sign) {
          if (Number(val.initVolume) >= Number(item.subMax)) {
            val.initVolume = "";
            this.$message({
              type: "warning",
              message: "分段" + (index + 1) + "的首方不可大于该段的最大值",
            });
          }
        }
      });
    },

    //添加重量
    addWeight() {
      if (this.weiCfgList.length == 20) {
        this.$message({
          type: "warning",
          message: "最多只能添加20条分段",
        });
        return;
      }
      let index = this.weiCfgList.length - 1;
      let item = JSON.parse(JSON.stringify(this.weiCfgList[index]));
      let obj = { ...item };
      for (let key in obj) {
        obj[key] = "";
        obj.subMin = item.subMax;
      }
      this.weiCfgList.push(obj);
    },
    //添加体积分段
    addVolume() {
      if (this.volCfgList.length == 20) {
        this.$message({
          type: "warning",
          message: "最多只能添加20条分段",
        });
        return;
      }
      let index = this.volCfgList.length - 1;
      console.log(index, "index");
      let item = JSON.parse(JSON.stringify(this.volCfgList[index]));
      let obj = { ...item };
      for (let key in obj) {
        obj[key] = "";
        obj.subMin = item.subMax;
      }
      this.volCfgList.push(obj);
    },

    //删除重量分段
    deleteSegments(index) {
      if (this.weiCfgList.length == 1) {
        this.$message({
          type: "warning",
          message: "最少保留一条分段",
        });
        return;
      }
      this.weiCfgList.splice(index, 1);
    },

    //删除体积分段
    deleteSegments2(index) {
      if (this.volCfgList.length == 1) {
        this.$message({
          type: "warning",
          message: "最少保留一条分段",
        });
        return;
      }
      this.volCfgList.splice(index, 1);
    },
    //省市区处理
    async getAddress() {
      try {
        let res = await addressList({});
        var list = res.rspBody;
        if (list.length > 0) {
          this.options = utils.addressData(list);
        } else {
          this.options = [];
        }
      } catch (err) {
        console.log(err);
      }
    },
    //时效类型
    async getAging() {
      try {
        let res = await agingType();
        this.agingList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    //产品类型
    async getProduct() {
      try {
        let res = await productType();
        this.goodsList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },
    //计费方式
    async getCharging() {
      try {
        let res = await billingMethod();
        this.chargeList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    async getPurchasePrice() {
      try {
        let res = await purchaseMethod();
        this.purchaseList = res.rspBody;
      } catch (err) {
        console.log(err);
      }
    },

    trimLR(e) {
      if (e.keyCode == 32) {
        e.returnValue = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add_line {
  width: 100%;
  height: 100%;
  // position: relative;

  .screenInput {
    width: 200px;
  }
  .check_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .formula {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 15px;
    .formula_text {
      color: #f56c6c;
    }
  }
  .weight_box {
    text-align: left;
    border: 1px solid #d8d8d8;
    padding: 10px;
    margin-bottom: 20px;

    .function_button {
      margin-bottom: 10px;
      .tips_text {
        color: #f56c6c;
      }
    }
    .subsection_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;
      .title {
        padding-right: 10px;
        width: 45px;
        .sign {
          color: #f56c6c;
        }
      }

      .section_box {
        .division {
          padding: 0 5px;
        }
      }
      .first_weight {
        padding-left: 20px;
        .weight_text {
          padding-right: 8px;
        }
      }

      .delete_btn {
        padding-left: 10px;
      }
      .inputStyle {
        width: 100px;
      }
      .inputStyle2 {
        width: 130px;
      }
    }
  }

  .bottom_btn {
    position: absolute;
    bottom: 0;
    left: 232px;
    right: 0;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>