import axios from "axios";
import router from "../../router/index"
import baseURL from '../../api/evn';

console.log(window.location.href)
console.log(baseURL.BASE_URL)
//创建一个axios对象
const instance = axios.create({
  baseURL: baseURL.BASE_URL,
  timeout: 5000,
});

//请求拦截 ---- 请求发起之前操作
instance.interceptors.request.use((config) => {
  // console.log(config,'config')
  if (config) {
    const token = localStorage.getItem("token");
    // const token = "eyJhbGciOiJIUzI1NiIsInppcCI6IkdaSVAifQ.H4sIAAAAAAAAAKtWKi5NUrJSMlTSUSotTi3yTFGyMtRRyslPz8wLqSxIBfKMdJRSKwqADDMzU2NDEwtzi1oAZv7dTTYAAAA.SsBCTqSGXGbtKNXfhY12mr6z1FHFFBekRcu2VwdVQaA";
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  }
}),
  (error) => {
    console.log("请求拦截", error);
    Promise.reject(error);
  };

//响应拦截器 ---- 服务器返回数据都会先执行这个方法
instance.interceptors.response.use((response) => {
  // console.log(response,'response')
  if (response) {
    // const token = localStorage.getItem("token");
    // const token = "eyJhbGciOiJIUzI1NiIsInppcCI6IkdaSVAifQ.H4sIAAAAAAAAAKtWKi5NUrJSMlTSUSotTi3yTFGyMtRRyslPz8wLqSxIBfKMdJRSKwqADDMzU2NDEwtzi1oAZv7dTTYAAAA.SsBCTqSGXGbtKNXfhY12mr6z1FHFFBekRcu2VwdVQaA";
    if (response.data.retCode == 401) {
      // this.$message({
      //   type: 'error',
      //   message:response.data.retDesc
      // })
      router.push({
        path:'/login',
      })
      // if (!token) {
      //   router.push({
      //     path:'/login',
      //   })
      // }
    }
    return response.data;
  }
}),
  (error) => {
    console.log("响应拦截器", error);
    Promise.reject(error);
  };


  export default instance