import request from "@/plugin/axios/index";

//登录接口
export function login(data) {
  return request({
    url: "",
    method: "Post",
    data,
  });
}

//线路管理列表
export function lineList(data) {
  return request({
    url: "/path/list",
    method: "Post",
    data,
  });
}

//时效类型
export function agingType(data) {
  return request({
    url: "/path/enum/getSpeedTypeE",
    method: "Post",
    data,
  });
}

//产品类型
export function productType(data) {
  return request({
    url: "/path/enum/getGoodsTypeE",
    method: "Post",
    data,
  });
}

//有效状态
export function validStatus(data) {
  return request({
    url: "/path/enum/getValidFlgE",
    method: "Post",
    data,
  });
}

// 省市区接口
export function addressList(data) {
  return request({
    url: "/ts/nationDict/getBasicData",
    method: "Post",
    data,
  });
}

//计费方式
export function billingMethod(data) {
  return request({
    url: "/path/enum/getChargeTypeE",
    method: "Post",
    data,
  });
}

//进价方式
export function purchaseMethod(data) {
  return request({
    url: "/path/enum/getRoundTypeE",
    method: "Post",
    data,
  });
}

//线路管理新增
export function addLine(data) {
  return request({
    url: "/path/save",
    method: "Post",
    data,
  });
}

//线路详情
export function lineDetails(data) {
  return request({
    url: "/path/getByVo",
    method: "Post",
    data,
  });
}

//线路管理删除
export function lineDeletion(data) {
  return request({
    url: "/path/delete",
    method: "Post",
    data,
  });
}

//线路管理-人工终止
export function terminationList(data) {
  return request({
    url: "/path/setValidFlg",
    method: "Post",
    data,
  });
}

//承运商获取
export function getFindCompName(data) {
  return request({
    url: "/au/company/findCompNames",
    method: "Post",
    data,
  });
}

//承运商分配
export function setCompany(data) {
  return request({
    url: "/path/setCompany",
    method: "Post",
    data,
  });
}

//订单管理获取
export function orderList(data) {
  return request({
    url: "/lcl/yy/order/list",
    method: "Post",
    data,
  });
}

//订单管理-获取扫码数据
export function scanCode(data) {
  return request({
    url: "/lcl/yy/order/scan/list",
    method: "Post",
    data,
  });
}

//承运商查询列表
export function companyFind(data) {
  return request({
    url: "/au/company/find",
    method: "Post",
    data,
  });
}

//承运商列表
export function findCompNames(data) {
  return request({
    url: "/au/company/findCompNames",
    method: "Post",
    data,
  });
}

//承运商新增
export function addCarrier(data) {
  return request({
    url: "/au/company/register",
    method: "Post",
    data,
  });
}

//承运商公司名下账号
export function userWhite(data) {
  return request({
    url: "/yh/userWhite/whiteList",
    method: "Post",
    data,
  });
}


//承运商编辑
export function companyUpdate(data) {
  return request({
    url: "/au/company/update",
    method: "Post",
    data,
  });
}

//承运商管理-登录手机号删除
export function removeUserWhite(data) {
  return request({
    url: "/yh/userWhite/remove",
    method: "Post",
    data,
  });
} 

//承运商管理-检验新增手机号是否存在
export function mobileVerify(data) {
  return request({
    url: "/yh/userWhite/mobileVerify",
    method: "Post",
    data,
  });
}


//托运人查询列表
export function shipperList(data) {
  return request({
    url: "/yh/business/user/get/web/user/list",
    method: "Post",
    data,
  });
}


//托运人详情
export function shipperDetail(data) {
  return request({
    url: "/yh/business/user/get/web/user/detail",
    method: "Post",
    data,
  });
}


//
