//去除空字符串
function removeNull(obj) {
  for (let key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  return obj;
}

//重置查询条件
function clearQuery(obj) {
  for (let key in obj) {
    obj[key] = "";
  }
  return obj;
}

//后端反悔的省市区  针对于四个直辖市只有两级联动  手动处理成三级联动
function addressData(list) {
  list.forEach((item) => {
    // let bjList = [
    //   {
    //     // code: "110100",
    //     code: "110000",
    //     name: "北京市",
    //     children: [],
    //   },
    // ];
    // let shList = [
    //   {
    //     // code: "310100",
    //     code: "310000",
    //     name: "上海市",
    //     children: [],
    //   },
    // ];
    // let tjList = [
    //   {
    //     // code: "120100",
    //     code: "120000",
    //     name: "天津市",
    //     children: [],
    //   },
    // ];
    // let cqList = [
    //   {
    //     // code: "500100",
    //     code: "500000",
    //     name: "重庆市",
    //     children: [],
    //   },
    // ];
    if (item.cities) {
      //把数据节点cities换成children
      item.children = item.cities;
      item.children.forEach((key) => {
        //4个直辖市数据处理
        // if (item.code == "110000") {
        //   item.name = "北京";
        //   let arr = {
        //     code: key.code,
        //     name: key.name,
        //   };
        //   bjList[0].children.push(arr);
        //   item.children = bjList;
        // } else if (item.code == "310000") {
        //   item.name = "上海";
        //   let arr = {
        //     code: key.code,
        //     name: key.name,
        //   };
        //   shList[0].children.push(arr);
        //   item.children = shList;
        // } else if (item.code == "120000") {
        //   item.name = "天津";
        //   let arr = {
        //     code: key.code,
        //     name: key.name,
        //   };
        //   tjList[0].children.push(arr);
        //   item.children = tjList;
        // } else if (item.code == "500000") {
        //   item.name = "重庆";
        //   let arr = {
        //     code: key.code,
        //     name: key.name,
        //   };
        //   cqList[0].children.push(arr);
        //   item.children = cqList;
        // }
        if (key.areas) {
          key.children = key.areas;
          if (key.children.length == 0) {
            key.children = undefined
          }
        }
      });
    }
  });
  return list;
}
let utils = {
  removeNull: removeNull, //去除空字符串
  clearQuery: clearQuery, //重置查询条件
  addressData: addressData, // 省市区处理
};

export default utils;
